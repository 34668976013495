/*jshint esversion: 6 */

(function(){

    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Set up the responsive main menu dialog
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    let maxDialogWidth = 992; // bootstrap5 lg size
    let dialogHeight = 0;
    let dialogSelector = '#main-menu-dialog';

    $(function(){
        console.log('ready window height: '+$(window).height());
        let dialogSettings = {
            autoOpen: false,
            width: Math.min($(window).width(), maxDialogWidth),
            height: $(window).height(),
            draggable: false,
            resizable: false,
            modal: true,
            dialogClass: "main menu",
            position: {
                my: "center top",
                at: "center top",
                of: "body"
            },
            title: "",
            show: {
                effect: "blind",
                duration: 500
            },
            hide: {
                effect: "blind",
                duration: 500
            },
            open: function(){
                $('.ui-widget-overlay').bind('click',function(){
                    $(dialogSelector).dialog('close');
                })
            },
            closeOnEscape: true,
        };

        if($(window).width() > maxDialogWidth) {
            delete dialogSettings.height;
            dialogSettings.position = {
                my: "left top",
                at: "left bottom",
                of: $("button.main.menu")
            }
        }

        $(dialogSelector).dialog(dialogSettings);

        $('button.main.menu').click(function(){
            $(dialogSelector).dialog("open");
        });

        // Mobile devices change width depending on orientation and this will resize the width accordingly.
        // It is also helpful for testing e.g. in responsive chrome dev tools the dialog will stay full width.
        $(window).resize(function () {
            if($(window).width() <= maxDialogWidth) {
                $('#main-menu-dialog').dialog('option', 'width', $(window).width());
            }
        });
    });
    //console.log('initial js window height: '+$(window).height());
})();
